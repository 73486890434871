import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { FaHome, FaMapMarkedAlt } from "react-icons/fa"
import ItemGallery from "../components/ItemGallery"
import SEO from "../components/seo"
import PreviewCompatibleImage from "../components/previewCompatibleImage"

const AtractionWrapper = styled.article`
  .article-container {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .gallery {
    margin: 4rem 0;
    display: flex;
    justify-content: space-between;
  }
  .content {
    text-align: justify;
    text-justify: inter-word;
  }
  .content ::after {
    content: "";
    width: 40px;
    height: 2px;
    background-color: rgba(238, 120, 121, 0.6);
    display: block;
    margin: 1rem 0;
  }
`
const NavWrapper = styled.nav`
  bottom: -25px;
  z-index: 1;
  position: absolute;
  display: flex;

  .to-item-link,
  .to-group-link {
    display: flex;
    align-items: center;
    min-width: 220px;
  }
  .to-group-link {
    background-color: white;
    margin-right: 1rem;
  }

  @media (max-width: 514px) {
    flex-direction: column;
    bottom: -50px;
    .to-item-link,
    .to-group-link {
      padding: 0.6rem 0.6rem;
    }
    .to-group-link {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

export const AtractionPageTemplate = ({ title, image, images, content }) => {
  return (
    <section>
      <PreviewCompatibleImage
        style={{ width: "100vw", maxHeight: "60vh" }}
        imageInfo={image}
      />
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          padding: "0 1rem",
          maxWidth: "1240px",
        }}
      >
        <NavWrapper className="back-btn">
          <Link className="to-group-link" to="/">
            <FaHome style={{ color: "#ee7879" }} />
            <span style={{ marginLeft: "1rem" }}>Strona Główna</span>
          </Link>
          <Link className="to-item-link" to="/atrakcje/">
            <FaMapMarkedAlt />
            <span style={{ marginLeft: "1rem" }}>Pozostałe atrakcje</span>
          </Link>
        </NavWrapper>
      </div>
      <div className="container">
        <AtractionWrapper>
          <div className="article-container">
            <header>
              <h1>{title}</h1>
            </header>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="gallery">
              <ItemGallery gallery={images} />
            </div>
          </div>
        </AtractionWrapper>
      </div>
    </section>
  )
}

const AtractionPage = ({ data }) => {
  const { frontmatter: atraction } = data.markdownRemark
  const { seo } = atraction
  return (
    <Layout>
      <SEO
        title={seo.browserTitle}
        description={seo.description}
        keywords={seo.keywords}
      />
      <AtractionPageTemplate
        title={atraction.title}
        image={atraction.atractionImage}
        images={atraction.images}
        content={data.markdownRemark.html}
      />
    </Layout>
  )
}

AtractionPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query AtractionPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        atractionImage {
          image {
            childImageSharp {
              fluid(
                quality: 70
                traceSVG: {
                  color: "#ee7879"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: true
                }
                maxWidth: 1680
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
        images {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
        seo {
          browserTitle
          description
          keywords
        }
      }
    }
  }
`

export default AtractionPage
